.custom-text-primary{
  --bs-text-opacity: 1;
  color: rgb(90 201 181 / var(--tw-bg-opacity));
}

.custom-text-secondary{
  --bs-text-opacity: 1;
  color: rgb(163 119 255 / var(--tw-bg-opacity));
}

.custom-bg-primary{
  --tw-bg-opacity: 1;
  background-color: rgb(90 201 181 / var(--tw-bg-opacity));
}

.custom-bg-secondary{
  --tw-bg-opacity: 1;
  background-color: rgb(163 119 255 / var(--tw-bg-opacity));
}

.custom-btn-primary{
  --tw-bg-opacity: 1;
  background-color: rgb(90 201 181 / var(--tw-bg-opacity));
}

.custom-btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(163 119 255 / var(--tw-bg-opacity));
}

.custom-btn-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.custom-btn-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(163 119 255 / var(--tw-bg-opacity));
}

.custom-btn-secondary{
  --tw-bg-opacity: 1;
  background-color: rgb(163 119 255 / var(--tw-bg-opacity));
}

.custom-btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 201 181 / var(--tw-bg-opacity));
}

.custom-btn-warning{
  --tw-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb),var(--bs-bg-opacity));
}

.custom-btn-warning:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(163 119 255 / var(--tw-bg-opacity));
}

.modal-content {
  max-height: calc(100vh - 100px); /* Adjust as needed */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the max height */
}

.react-date-picker {
  background-color: white;
  width: 200px;
}

/* Override text color */


.custom-link {
  --bs-text-opacity: 1;
  color: #767676;
}

.custom-link:hover {
  --bs-text-opacity: 1;
  color: rgb(90 201 181 / var(--tw-bg-opacity));
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: rgb(90 201 181 / var(--tw-bg-opacity));
}

.react-date-picker__button .react-date-picker__button__icon {
  stroke: rgb(90 201 181 / var(--tw-bg-opacity));
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
  color: white;
}



.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 1;
  color: black;
}

.hand-cursor {
  cursor: pointer;
}
.react-calendar__tile--now {
  color: black;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(90 201 181 / var(--tw-bg-opacity))!important;
}

.react-calendar__tile--active {
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(163 119 255 / var(--tw-bg-opacity))!important;
  color: black;
}

[type=button], [type=reset], [type=submit], button {
  background-color: transparent;
  background-image: unset;
}

.bullet-li {
  list-style-type: none;
}

.bullet-li::before {
  content: "- "; /* Add hyphen before each list item */
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 80%;
}

td, th {
  border: 1px solid #767676;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: black;
}

tr:nth-child(odd) {
  background-color: rgb(163 119 255 / var(--tw-bg-opacity));
}
.center {
  margin-left: auto;
  margin-right: auto;
}

.custom-select {
  position: relative;
  cursor: pointer;
}

.selected-option {
  padding: 10px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgb(90 201 181 / var(--tw-bg-opacity));
}

.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: rgb(163 119 255 / var(--tw-bg-opacity));
}


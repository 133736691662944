@keyframes moveFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .right-to-center-modal .modal-dialog {
    animation: moveFromRight 0.5s forwards;
  }